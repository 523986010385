import React from "react"

const Titles =({subtitle, quote, author})=> {
  return(
    <div className="team--titles grid-container">
      <h2 className="heading" data-sal="slide-up" data-sal-delay="150" data-sal-duration="1000" data-sal-easing="ease">Team</h2>
      <h3 data-sal="slide-left" data-sal-delay="150" data-sal-duration="700" data-sal-easing="ease">{subtitle}</h3>
      <p data-sal="slide-up" data-sal-delay="150" data-sal-duration="500" data-sal-easing="ease">{quote}</p>
      <p data-sal="slide-up" data-sal-delay="150" data-sal-duration="500" data-sal-easing="ease">-{author}</p>
    </div>
  )
}

export default Titles
