import React, {useState} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Titles from "../components/team/titles"
import Member from "../components/team/member"
import Un from "../components/team/un"
import Modal from "../components/team/modal"

export const query = graphql`
query {
  sanityTeam {
    subtitle
    quote
    author
    _rawText
    maria {
      _rawBio
      name
      image {
        asset {
          fluid(maxWidth: 1080) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    janine {
      _rawBio
      name
      image {
        asset {
          fluid(maxWidth: 1080) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
    manuel {
      _rawBio
      name
      image {
        asset {
          fluid(maxWidth: 1080) {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
}
`

const Team = ({data}) => {
  const
    {author, quote, subtitle, maria, manuel, janine, _rawText} = data.sanityTeam,
    members = [maria, janine, manuel],
    [modalVisible, setModal] = useState(false),
    [currentMember, setMember] = useState("");

    const getCurrentMember = (name) => {
      if(name === "María") {
        return setMember(maria)
      } else if (name === "Janine") {
        return setMember(janine)
      } else if (name === "Manuel") {
        return setMember(manuel)
      }
    }

    const openModal = (name) => {
      setModal(true)
      getCurrentMember(name)
    }

  return(
    <Layout>
      <SEO title="Team" />
      {modalVisible && (
        <Modal member={currentMember} closeModal={() => setModal(false)} />
      )}
      <Titles subtitle={subtitle} quote={quote} author={author} />

      <div className="team--members">
        {
          members.map(member => {
            return(
              <Member name={member.name} image={member.image} openModal={openModal} bio={member._rawBio}/>
            )
          })
        }
      </div>

      <Un text={_rawText}/>

    </Layout>
  )
}

export default Team
