import React from "react"
import {Link} from "gatsby"

import CTAarrow from "../../assets/svg/ubuntu-arrow-cta.svg"
import UbuntuUn from "../../assets/svg/ubuntu-un.svg"
import BlockContent from "../block-content"

const Un = ({text}) => {
  return(
    <React.Fragment>
      <div data-sal="fade" data-sal-delay="350" data-sal-duration="1200" data-sal-easing="ease" className="team--un">
        <UbuntuUn/>
        <BlockContent className="text-container" blocks={text || []}/>
      </div>
      <div className="cta-container un">
        <Link to="/services">What we do</Link>
        <Link to="/services"><CTAarrow className="clickable"/></Link>
      </div>
    </React.Fragment>
  )
}

export default Un
