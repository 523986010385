import React, {useState} from "react"
import Image from "../../components/image"
import BlockContent from "../../components/block-content"

const Member =({name, image, openModal, bio})=> {
  const [open, setOpen] = useState(false)
  return(
    <div className="member">
      <h3 data-sal="slide-up" data-sal-delay="150" data-sal-duration="700" data-sal-easing="ease">{name}</h3>
      <div data-sal="fade" data-sal-delay="150" data-sal-duration="700" data-sal-easing="ease">
        <Image fluid={image.asset.fluid} alt={`Experience Ubuntu ${name}`}/>
      </div>
      <p className="clickable big" onClick={() => openModal(name)} >view full bio</p>
      {!open && <p className="clickable small" onClick={() => setOpen(true)} >view full bio</p>}
      {!!open && (
        <div className="bio-small">
          <p className="clickable small" onClick={() => setOpen(false)} >hide full bio</p>
          <BlockContent blocks={bio || []}/>
        </div>
      )}
    </div>
  )
}

export default Member
