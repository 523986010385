import React from "react"
import BlockContent from "../block-content"
import closeIcon from "../../assets/images/ubuntu-close.svg"

const Modal =({member, closeModal})=> {
  return(
    <div className={`member--modal modal-${member.name}`}>
      <div className="modal--header">
        <h3>{member.name}</h3>
        <div className="modal--header-close clickable">
          <img className="clickable" src={closeIcon} alt="close" onClick={() => closeModal()}/>
        </div>
      </div>
      <div className="modal--bio">
        <BlockContent blocks={member._rawBio || []}/>
      </div>
    </div>
  )
}

export default Modal
